/* global bz_app, isMobile, Track */
bz_app.factory('trackSearchContractorsService', [
  function () {
    return {
      title: 'Search Contractors Action',
      filterClicked: function (
        filterType,
        filterValue,
        filterSelected,
        callback
      ) {
        let data = this.defaultData({ action: 'filter clicked' })
        data.filter_type = filterType
        data.filter_value = filterValue
        data.filter_selected = filterSelected

        Track.send_callback(this.title, data, callback)
      },
      reviewClicked: function () {
        let contractorNameData = this.defaultData({ action: 'review clicked' })
        contractorNameData['Content Type'] = 'contractor name'
        let reviewBodyData = this.defaultData({ action: 'review clicked' })
        reviewBodyData['Content Type'] = 'review body'
        Track.send_link(
          '.reviewed-contractor-name',
          this.title,
          contractorNameData
        )
        Track.send_link('.sidebar-review-body', this.title, reviewBodyData)
      },
      viewProfileClick: function () {
        Track.send_link(
          '.primary-view-profile-button',
          this.title,
          this.defaultData({ action: 'view profile clicked', type: 'primary' })
        )
        Track.send_link(
          '.secondary-view-profile-button',
          this.title,
          this.defaultData({
            action: 'view profile clicked',
            type: 'secondary',
          })
        )
      },
      defaultData: function (input) {
        return angular.extend(
          {
            platform: isMobile.check() ? 'mobile' : 'desktop',
          },
          input
        )
      },
    }
  },
])
