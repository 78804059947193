bz_app.factory('citiesPermitSumModel', [
  '$rootScope',
  '$filter',
  'buildingPermitModel',
  function ($rootScope, $filter, buildingPermitModel) {
    return {
      trendData: [],
      summaryData: {},
      loadData: function (location) {
        var that = this

        return buildingPermitModel
          .load_permits_sum_by_year_in_city(location.slug)
          .then(function (response) {
            that.processData(response)
          })
      },
      processData: function (response) {
        if (response.success) {
          this.setSummary(response.summary)
          this.setTrend(response.trend)
        }
      },
      setSummary: function (summary) {
        if (summary) {
          this.summaryData = summary
        }
      },
      setTrend: function (trend) {
        if (trend) {
          var that = this

          angular.forEach(trend, function (item) {
            that.trendData.push({
              c: [
                { v: item.year },
                { v: item.num_permits },
                { v: $filter('number')(item.num_permits) },
              ],
            })
          })
        }
      },
    }
  },
])
