bz_app.controller('StateServiceCtrl', [
  '$scope',
  '$sce',
  'stateServiceModel',
  function ($scope, $sce, stateServiceModel) {
    $scope.details = {
      state: null,
      year: null,
      contractorTypeId: null,
      contractorType: null,
      aboutText: '',
    }

    $scope.init = function (input) {
      if (input.state !== null && input.state !== undefined)
        $scope.details.state = input.state
      if (input.year !== null && input.year !== undefined)
        $scope.details.year = input.year
      if (
        input.contractorTypeId !== null &&
        input.contractorTypeId !== undefined
      )
        $scope.details.contractorTypeId = input.contractorTypeId
      if (
        input.constructionType !== null &&
        input.constructionType !== undefined
      )
        $scope.details.constructionType = input.constructionType
      if (input.year !== null && input.aboutText !== undefined)
        $scope.details.aboutText = input.aboutText
    }

    $scope.aboutTextHTML = function () {
      return $sce.trustAsHtml($scope.details.aboutText)
    }

    $scope.confirmUpdate = function () {
      if (confirm('Update this text?')) return true
      else return ''
    }

    $scope.updateAboutText = function () {
      stateServiceModel.setStateYearServiceAboutText($scope.details)
    }
  },
])
