bz_app.controller('CityNavCtrl', [
  '$window',
  '$timeout',
  '$scope',
  function ($window, $timeout, $scope) {
    this.init = function (sections) {
      this.currentIndex = 0
      this.sections = sections
      this.windowElement = angular.element($window)
      $timeout(
        function () {
          this.setSectionElements()
          this.setScrollListener()
          this.handleScroll()
        }.bind(this)
      )
      this.initAffix()
    }

    this.initAffix = function () {
      var element = angular.element('nav#city-nav')
      element.affix({
        offset: {
          top: element.offset().top - 116,
        },
      })
    }

    this.setSectionElements = function () {
      this.sectionIndexes = {}
      angular.forEach(
        this.sections,
        function (section, key) {
          section.element = angular.element(section.selector)
          section.offsetTop = section.element.offset().top
          this.sectionIndexes[section.index] = key
        }.bind(this)
      )
    }

    this.setScrollListener = function () {
      this.windowElement.on('scroll', this.handleScroll.bind(this))
    }

    this.handleScroll = function () {
      var scrollTop = this.windowElement.scrollTop()
      if (!this.scrollTop || Math.abs(this.scrollTop - scrollTop) > 10) {
        this.scrollTop = scrollTop
        this.setSectionIndex()
      }
    }

    this.setSectionIndex = function () {
      var currentIndex = this.visibleSection().index
      if (currentIndex !== this.currentIndex) {
        this.currentIndex = currentIndex
        $scope.$apply()
      }
    }

    this.visibleSection = function () {
      var mostVisible
      angular.forEach(
        this.sections,
        function (section, _) {
          if (
            !mostVisible ||
            Math.abs(this.scrollTop - section.offsetTop) <
              Math.abs(this.scrollTop - mostVisible.offsetTop)
          ) {
            mostVisible = section
          }
        }.bind(this)
      )

      return mostVisible
    }

    this.sectionIsVisible = function (sectionName) {
      return this.sections[sectionName].index === this.currentIndex
    }

    this.scrollToSection = function (sectionName) {
      this.currentIndex = this.sections[sectionName].index
      $window.scrollTo(0, this.sections[sectionName].offsetTop - 110)
    }

    this.scrollToNextSection = function () {
      var targetIndex = (this.currentIndex + 1) % this.numberOfSections()
      this.scrollToSection(this.sectionIndexes[targetIndex])
    }

    this.scrollToPrevSection = function () {
      var targetIndex =
        this.currentIndex === 0
          ? this.numberOfSections() - 1
          : this.currentIndex - 1
      this.scrollToSection(this.sectionIndexes[targetIndex])
    }

    this.numberOfSections = function () {
      return Object.keys(this.sections).length
    }

    this.navItemWidth = function () {
      if (!this._navItemWidth) {
        this._navItemWidth = 100.0 / this.numberOfSections()
      }

      return this._navItemWidth.toString() + '%'
    }

    this.underlinePosition = function () {
      return (this.currentIndex * this._navItemWidth).toString() + '%'
    }
  },
])
