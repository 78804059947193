bz_app.controller('CollapsibleCtrl', [
  '$scope',
  function ($scope) {
    $scope.isCollapsed = true

    $scope.toggleCollapsed = function () {
      this.isCollapsed = !this.isCollapsed
    }
  },
])
