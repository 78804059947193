/* globals bz_app */
bz_app.component('recentProjects', {
  bindings: {
    properties: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        'search_contractors/directives/recent_projects'
      )
    },
  ],
  controller: [
    function () {
      this.recentProject = function (property) {
        return property &&
          property.relevant_permits &&
          property.relevant_permits.length
          ? property.relevant_permits[0]
          : null
      }
    },
  ],
})
