/* global bz_app, angular */
bz_app.directive('bidBanner', [
  'urlService',
  function (urlService) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {},
      controllerAs: '$ctrl',
      bindToController: {
        location: '=?',
        descriptor: '@',
        targetSelector: '@',
      },
      templateUrl: urlService.templateUrl('_components/directives/bid_banner'),

      controller: [
        '$window',
        '$timeout',
        function ($window, $timeout) {
          this.$onInit = function () {
            this.setLocationText()
            this.setDescriptorText()

            $timeout(
              function () {
                this.setRevealListener()
              }.bind(this),
              150
            )
          }

          this.setRevealListener = function () {
            var $w = angular.element($window)
            var $bannerEl = angular.element('#bid-banner')[0]
            var target = angular.element(this.targetSelector)
            var targetOffset = target.offset().top
            var targetHeight = target.outerHeight()
            var navHeight = angular.element('#modern-nav').outerHeight()
            $w.on(
              'scroll',
              this.checkForReveal.bind(
                this,
                $w,
                $bannerEl,
                target.offset().top,
                targetHeight,
                navHeight
              )
            )

            this.checkForReveal(
              $w,
              $bannerEl,
              targetOffset,
              targetHeight,
              navHeight
            )
          }

          this.checkForReveal = function (
            $w,
            $bannerEl,
            targetOffset,
            targetHeight,
            navHeight
          ) {
            if (targetOffset - $w.scrollTop() - navHeight + targetHeight < 0) {
              $bannerEl.classList.add('visible')
            } else {
              $bannerEl.classList.remove('visible')
            }
          }

          this.setLocationText = function () {
            if (this.location) {
              var locationArr = []
              if (this.location.city && this.location.city.length) {
                locationArr.push(this.location.city)
              }
              if (this.location.state && this.location.state.length) {
                locationArr.push(this.location.state)
              }

              if (locationArr.length) {
                this.locationText = locationArr.join(', ')
              }
            }
          }

          this.setDescriptorText = function () {
            if (this.descriptor && this.descriptor.length) {
              this.descriptorText = this.descriptor
            } else {
              this.descriptorText = 'contractors'
            }
          }
        },
      ],
    }
  },
])
