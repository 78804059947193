bz_app.factory('trustBzSlidesData', function () {
  return [
    {
      header: 'BuildZoom is powered by Data',
      body:
        "To identify great contractors, we analyze the industry's largest collection of " +
        'data, including 3.5 million license records and 120 million building permits.',
    },
    {
      header: 'Contractors are held accountable',
      body:
        'If a contractor does poor work, they will no longer receive projects from BuildZoom, ' +
        'and a negative review will be posted on their profile.',
    },
    {
      header: "We've partnered with some of the best minds out there",
      body:
        "In collaboration with the MIT Urban Economics Lab, we've developed an index to " +
        'track construction and remodeling across the nation.',
    },
    {
      header: 'Our proven track record',
      body:
        'Every month, we help over a million people connect with the best contractor for ' +
        'their job.',
    },
    {
      header: 'We leave no stone unturned',
      body:
        'We have analyzed every licensed contractor in the US - over 3 million - to make ' +
        'sure we find the best option for your project.',
    },
    {
      header: 'Our fact-based approach',
      body:
        'We use hard data and a mathematical approach to take subjectivity out of the ' +
        'decision making process.',
    },
  ]
})
