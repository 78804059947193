bz_app.controller('CityContractorsCtrl', [
  '$scope',
  'cityContractorsModel',
  '$timeout',
  'urlService',
  '$httpParamSerializer',
  function (
    $scope,
    cityContractorsModel,
    $timeout,
    urlService,
    $httpParamSerializer
  ) {
    this.init = function (data) {
      this.activeContractorType = data.activeContractorType
      this.years = data.years
      this.activeYear = data.activeYear
      this.location = data.location
      this.locationText = `${data.location.city}, ${data.location.state}`
      this.loadingContractors = false
      this.searchFormElement = angular.element(data.searchFormSelector)

      this.loadServices()
      this.setFilterListeners()
    }

    this.loadServices = function () {
      cityContractorsModel.topContractorsServices(this.filterData()).then(
        (response) => {
          if (response.success) {
            this.contractorTypes = response.contractorTypes
          }
        },
        () => {}
      )
    }

    this.setFilterListeners = function () {
      $scope.$watch(
        function () {
          return this.activeContractorType
        }.bind(this),
        this.callbackIfDifferent(this.searchContractors.bind(this))
      )
      $scope.$watch(
        function () {
          return this.activeYear
        }.bind(this),
        this.callbackIfDifferent(this.searchContractors.bind(this))
      )
    }

    this.callbackIfDifferent = function (callback) {
      return function (newValue, oldValue) {
        if (newValue !== oldValue) {
          callback()
        }
      }
    }

    this.searchContractors = function () {
      this.loadingContractors = true
      cityContractorsModel
        .searchContractors(this.filterData())
        .then(this.processContractorsPayload.bind(this))
      this.setQueryString()
    }

    this.setQueryString = function () {
      var query = $httpParamSerializer({
        year: this.activeYear,
        contractor_type: this.activeContractorType.slug,
      })
      urlService.setUrl('/' + this.location.slug + '?' + query)
    }

    this.searchUrl = function () {
      return this.location.slug + '/' + this.activeContractorType.slug
    }

    this.processContractorsPayload = function (data) {
      if (data.success) {
        this.contractors = data.contractors
        this.updated = true
        $timeout(
          function () {
            this.loadingContractors = false
          }.bind(this)
        )
      }
    }

    this.filterData = function () {
      return {
        location: this.location.slug,
        contractor_type: this.activeContractorType.slug,
        year: this.activeYear,
      }
    }
  },
])
