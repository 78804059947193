bz_app.factory('contractorsData', [
  '$rootScope',
  'contractorService',
  '$timeout',
  function ($rootScope, contractorService, $timeout) {
    return {
      contractors: [],
      page: 1,
      pageSize: null,
      total: null,
      timestamp: null,
      top10ContractorsHavePhotos: false,
      init: false,

      set: function (input) {
        $timeout(
          function () {
            $rootScope.$apply(
              function () {
                this.topContractorsWithImagesCount = 0

                if (input.contractors !== undefined) {
                  this.contractors = []

                  input.contractors.forEach(
                    function (contractor, index) {
                      var parsedContractor =
                        contractorService.parse_api_response(
                          contractor,
                          'Search'
                        )
                      this.contractors.push(parsedContractor)

                      if (
                        parsedContractor.cover_url &&
                        !parsedContractor.cover_url_generic &&
                        index < 10
                      ) {
                        this.topContractorsWithImagesCount++
                      }
                    }.bind(this)
                  )
                }

                if (input.page !== undefined) this.page = input.page
                if (input.page_size !== undefined)
                  this.pageSize = input.page_size
                if (input.total !== undefined) this.total = input.total
                if (input.timestamp !== undefined)
                  this.timestamp = input.timestamp
                if (input.project_type_counts !== undefined)
                  this.projectTypeCounts = input.project_type_counts

                if (
                  this.topContractorsWithImagesCount === 10 &&
                  this.page === 1
                ) {
                  this.top10ContractorsHavePhotos = true
                }

                this.init = true

                $rootScope.$broadcast('searchContractors.contractorsData.set')
              }.bind(this)
            )
          }.bind(this)
        )
      },
      totalPages: function () {
        return Math.ceil(this.total / this.pageSize)
      },
    }
  },
])
