/* global bz_app, angular, isMobile, UserData */
bz_app.component('contractorResult', {
  bindings: {
    contractor: '<',
    projectTypeCounts: '<',
    isPrimary: '<',
    index: '<',
    last: '<',
    showRank: '<',
    label: '@',
  },
  templateUrl: [
    'urlService',
    '$attrs',
    function (urlService, $attrs) {
      return urlService.templateUrl(
        'search_contractors/directives/contractor_' +
          ($attrs.isPrimary === 'true' ? 'primary' : 'secondary') +
          '_result'
      )
    },
  ],
  controller: [
    '$filter',
    'urlService',
    'applicationConstants',
    'contractorService',
    'urlConstants',
    'userInfoService',
    '$sce',
    function (
      $filter,
      urlService,
      applicationConstants,
      contractorService,
      urlConstants,
      userInfoService,
      $sce
    ) {
      this.$onInit = function () {
        this.userInfoService = userInfoService
        this.contractorService = contractorService
        this.constants = applicationConstants
        this.urlService = urlService
        this.trustAsHtml = $sce.trustAsHtml
        this.showRank = this.showRank === undefined
        this.initProjectTypeCounts()
      }

      this.initProjectTypeCounts = function () {
        this.projectTypeCounts =
          this.projectTypeCounts === undefined ? [] : this.projectTypeCounts
      }

      this.contractorScore = function () {
        return this.contractor.score || 'ALERT*'
      }

      this.contractorPermitsCount = function () {
        if (this.contractorHasPermits(this.contractor)) {
          if (this.contractor.permit_aggregates.num_permits >= 10000) {
            return $filter('currency_short')(
              this.contractor.permit_aggregates.num_permits,
              '_',
              1
            )
          } else {
            return $filter('number')(
              this.contractor.permit_aggregates.num_permits
            )
          }
        } else {
          return '-'
        }
      }

      this.contractorProjectTypesCount = function () {
        return this.projectTypeCounts !== undefined
          ? this.projectTypeCounts.length
          : 0
      }

      this.getContractorProjectByIndex = function (projectIndex) {
        return this.projectTypeCounts[projectIndex]
      }

      this.projectIcon = function (projectName) {
        return this.projectTypeIcons[projectName] !== undefined
          ? this.projectTypeIcons[projectName]
          : 'tools'
      }

      this.contractorPermitsText = function () {
        return this.contractorPermitsCount() === 1 ? 'Project' : 'Projects'
      }

      this.contractorLicenseLabel = function () {
        var licenseLabel = isMobile.check() ? 'Lic #' : 'License #'
        return this.contractor.state === 'PA' ? 'HIC #' : licenseLabel
      }

      this.contractorHasPermits = function () {
        return (
          this.contractor.permit_aggregates &&
          this.contractor.permit_aggregates.num_permits
        )
      }

      this.contractorHasJobValue = function () {
        return (
          this.contractor.permit_aggregates &&
          this.contractor.permit_aggregates.median_job_value
        )
      }

      this.contractorMedianJobValue = function () {
        if (this.contractorHasJobValue()) {
          return $filter('currency_short')(
            this.contractor.permit_aggregates.median_job_value,
            '$',
            this.jobValuePrecision()
          )
        } else {
          return '-'
        }
      }

      this.jobValuePrecision = function () {
        var value = this.contractor.permit_aggregates.median_job_value
        return value >= 10000 && value < 1000000 ? 0 : 1
      }

      this.getTimes = function (n, roundType) {
        if (roundType === 'floor') {
          return new Array(Math.floor(n))
        } else if (roundType === 'ceil') {
          return new Array(Math.ceil(n))
        } else {
          return new Array(Math.round(n))
        }
      }

      this.thumbnailSize = function () {
        if (this.isMobile()) {
          return this.isPrimary ? 'square_400' : 'square_200'
        } else {
          return this.isPrimary ? 'rectangle_870x330' : 'square_300'
        }
      }

      this.isMobile = function () {
        return isMobile.check({ phoneOnly: true })
      }

      this.contractorAboutTruncationLength = function () {
        if (isMobile.check()) {
          return this.isPrimary ? 250 : 180
        } else {
          return this.isPrimary ? 550 : 400
        }
      }

      this.badgeIndicatorLabel = function () {
        if (this.contractor.is_caution) {
          return 'caution'
        }
      }

      this.alertContractorLicenseActionUrl = function () {
        return this.contractor && this.contractor.claimed
          ? urlConstants.contractor.licenseDashboardURL
          : urlConstants.contractor.claimURL + this.contractor.alias
      }

      this.showCoverImage = function () {
        return !!this.contractor.cover_url && !this.contractor.cover_url_generic
      }

      this.showContractorAbout = function () {
        return (
          'best_display_content' in this.contractor &&
          this.contractor.best_display_content.type === 'ContractorAbout'
        )
      }

      this.shouldTruncateText = function () {
        return (
          this.contractorAboutTruncationLength() <
          $sce.getTrustedHtml(this.contractorDescription()).length
        )
      }

      this.showContractorNotablePermit = function () {
        return (
          'best_display_content' in this.contractor &&
          this.contractor.best_display_content.type === 'BuildingPermit'
        )
      }

      this.showContractorReview = function () {
        return (
          'best_display_content' in this.contractor &&
          this.contractor.best_display_content.type === 'Review'
        )
      }

      this.showAlert = function () {
        return this.contractor.score === 0 && this.contractor.score_warning
      }

      this.contractorDescription = function () {
        var text = ''

        if (this.showContractorAbout()) {
          text = this.contractor.best_display_content.content
        } else if (this.showContractorNotablePermit()) {
          text = 'Notable project: '

          if (
            this.contractor.best_display_content &&
            this.contractor.best_display_content.content
          ) {
            if (this.contractor.best_display_content.content.type) {
              text += this.contractor.best_display_content.content.type + '. '
            }

            text +=
              this.contractor.best_display_content.content.full_address + '. '
            text += this.contractor.best_display_content.content.description
          }
        } else if (this.showContractorReview()) {
          text = 'Recent review'

          if (
            this.contractor.best_display_content &&
            this.contractor.best_display_content.content
          ) {
            if (this.contractor.best_display_content.content.name) {
              text += ' by ' + this.contractor.best_display_content.content.name
            }

            text += ': ' + this.contractor.best_display_content.content.comment
          }
        }

        return text
      }

      this.showAdminInfo = function () {
        return UserData.is_admin()
      }

      this.contractorLocation = function () {
        return this.contractor.city + ', ' + this.contractor.state
      }
    },
  ],
})
