/* global bz_app, angular, isMobile, $  */
bz_app.controller('SearchContractorsResultsCtrl', [
  '$scope',
  'searchContractorsModel',
  '$rootScope',
  'urlService',
  '$timeout',
  '$sanitize',
  'filtersService',
  'trackSearchContractorsService',
  function (
    $scope,
    searchContractorsModel,
    $rootScope,
    urlService,
    $timeout,
    $sanitize,
    filtersService,
    trackSearchContractorsService
  ) {
    this.init = function (data) {
      this.recentProjectsData = searchContractorsModel.recentProjectsData
      this.contractorsData = searchContractorsModel.contractorsData
      this.filtersData = searchContractorsModel.filtersData
      this.filtersData.set(data.filtersInput)
      this.filtersService = filtersService
      this.filtersService.isOpen = data.isOpen
      this.updateMapForResults =
        data.updateMapForResults !== undefined ? data.updateMapForResults : true
      this.listenForFiltersChange()

      this.isListingPage = angular.element('body.search_contractors').length
        ? true
        : false

      angular.element('.search-contractors-filters-tab i').removeClass('hidden')
      angular.element('.header-title-radius-indicator').removeClass('hidden')
      angular
        .element('.search-contractors-filters-options')
        .removeClass('hidden')

      trackSearchContractorsService.reviewClicked()
      trackSearchContractorsService.viewProfileClick()
    }

    this.listenForFiltersChange = function () {
      $timeout(
        function () {
          $scope.$watch(
            '$ctrl.filtersData',
            function (newValue, oldValue) {
              if (newValue === oldValue) {
                return
              }
              // for checkboxes need to reset page, which will update filters and cause another digest cycle
              // so don't search in this case, because there will be a search in the next cycle
              if (newValue.page !== 1) {
                this.filtersData.page = 1
              } else {
                this.search()
              }
            }.bind(this),
            true
          )
        }.bind(this),
        500
      )
    }

    this.search = function () {
      if (urlService.setUrl(searchContractorsModel.pageUrlWithFilters())) {
        searchContractorsModel.load().then(this.setData.bind(this))
      }
    }

    this.setHeader = function () {
      if (this.isListingPage) {
        var text =
          (this.filtersData.isRelevanceSort() && this.contractorsData.total
            ? 'Top 10 '
            : '') +
          this.filtersData.keywords +
          ' in ' +
          this.filtersData.locationName +
          ', ' +
          this.filtersData.locationState +
          (this.filtersData.mileRadiusOptions.maxCity ? '' : ' metro area')

        angular
          .element('.search_contractors .page-header-title h1')
          .html($sanitize(text))
      }
    }

    this.setData = function (response) {
      if (response.success && response.results) {
        searchContractorsModel.contractorsData.set(response.results)
        searchContractorsModel.recentProjectsData.set(
          response.results.properties
        )

        if (this.updateMapForResults) {
          $rootScope.$broadcast('properties.setPinsOnMap', {
            properties: response.results.properties,
          })
        }

        $rootScope.$broadcast('searchContractors.resultsSet')

        $timeout(() => {
          this.setHeader()
          trackSearchContractorsService.viewProfileClick()
        })

        filtersService.closeOnMobile()
        angular.element('#50-mile-radius-indicator').hide()
      }
    }

    this.trackFilterAction = function (action) {
      trackSearchContractorsService.filterClicked(
        action.filterType,
        action.filterValue,
        action.filterSelected
      )
    }

    this.clickFilterSelectors = function ($event, selectors) {
      angular.element('.header-tab').removeClass('header-tab-selected')
      angular.element($event.target).addClass('header-tab-selected')

      angular.forEach(selectors, function (selector) {
        $timeout(function () {
          angular.element(selector).click()
        })
      })
    }
  },
])
