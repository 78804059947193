/* global bz_app, angular, isMobile */
bz_app.component('targetedContractorResult', {
  bindings: {
    contractor: '<',
  },
  template:
    '<contractor-result ng-if="$ctrl.contractor" contractor="$ctrl.parsedContractor" is-primary="true" show-rank="false" label="Featured"></contractor-result>',

  controller: [
    'contractorService',
    'contractorModel',
    function (contractorService) {
      this.$onInit = function () {
        this.parsedContractor = contractorService.parse_api_response(
          this.contractor
        )
        this.parsedContractor.cover_url_generic = false
        this.parsedContractor.is_featured = true
      }
    },
  ],
})
