bz_app.component('cityContractorsResults', {
  bindings: {
    contractors: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        'cities/components/city_contractors_results'
      )
    },
  ],
  controller: [
    'contractorService',
    'urlService',
    function (contractorService, urlService) {
      this.$onInit = function () {
        this.contractorService = contractorService
        this.urlService = urlService
      }
    },
  ],
})
