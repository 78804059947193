bz_app.directive('trustBzSlides', [
  'urlService',
  function (urlService) {
    return {
      restrict: 'AE',
      replace: true,
      scope: {
        classes: '@',
        referralSource: '@',
      },
      templateUrl: urlService.templateUrl(
        'search_contractors/directives/trust_bz_slides'
      ),
      controller: [
        '$scope',
        '$timeout',
        'widgetTrackingService',
        'trustBzSlidesData',
        function ($scope, $timeout, widgetTrackingService, trustBzSlidesData) {
          $scope.activeSlide = 0
          $scope._waiting = false
          $scope.slides = trustBzSlidesData

          $scope.nextSlide = function () {
            if (this._waiting) {
              return
            }
            this.activeSlide = (this.activeSlide + 1) % this.slides.length
            this._waiting = true
            $timeout(
              function () {
                this._waiting = false
              }.bind(this),
              500
            )
            widgetTrackingService.whyTrustBuildZoomNextReasonClicked(
              this.activeSlide,
              $scope.referralSource
            )
          }

          $scope.slideIn = function (index) {
            return this.activeSlide == index
          }

          $scope.slideOut = function (index) {
            return this.activeSlide == (index + 1) % this.slides.length
          }

          $scope.header = function (index) {
            return 'Reason #' + (index + 1) + '. ' + this.slides[index].header
          }

          $scope.body = function (index) {
            return this.slides[index].body
          }
        },
      ],
    }
  },
])
