/* global bz_app, angular */
bz_app.component('searchResultsPagination', {
  controller: [
    '$rootScope',
    'searchContractorsModel',
    '$element',
    function ($rootScope, searchContractorsModel, $element) {
      this.$onInit = function () {
        this.listenForSearchResultsUpdate()
      }

      this.listenForSearchResultsUpdate = function () {
        $rootScope.$on(
          'searchContractors.contractorsData.set',
          function () {
            this.setPage(searchContractorsModel.contractorsData.page)
          }.bind(this)
        )
      }

      this.setPage = function (newPage) {
        if (searchContractorsModel.contractorsData.totalPages() > 1) {
          var paginationItems = this.paginationItems()
          var currentPage = this.currentPage(paginationItems)

          if (currentPage) {
            paginationItems
              .filter('.current')
              .html(
                '<a href="' +
                  searchContractorsModel.pageUrlWithFilters({
                    page: currentPage,
                  }) +
                  '">' +
                  currentPage +
                  '</a>'
              )
              .removeClass('current')
          }

          if (newPage === 1) {
            this.resetPagination()
          } else if (newPage > 1) {
            this.updatePagination(paginationItems, newPage)
          }
        } else {
          $element.empty() // search result has no pagination
        }
      }

      this.updatePagination = function (paginationItems, currentPage) {
        var offset = -1
        offset = offset * Number(paginationItems.first().find('a').html())
        paginationItems
          .eq(currentPage + offset)
          .html(currentPage)
          .addClass('current')

        var paginationLinkItems = paginationItems.not('.current')

        // page number links
        paginationLinkItems.each(function (index) {
          angular
            .element(this)
            .find('a')
            .attr(
              'href',
              searchContractorsModel.pageUrlWithFilters({
                page: angular.element(this).find('a').html(),
              })
            )
        })

        angular
          .element('.pagination .prev')
          .find('a')
          .attr(
            'href',
            searchContractorsModel.pageUrlWithFilters({ page: currentPage - 1 })
          ) // prev link
        angular
          .element('.pagination .next')
          .find('a')
          .attr(
            'href',
            searchContractorsModel.pageUrlWithFilters({ page: currentPage + 1 })
          ) // next link

        if (
          currentPage === searchContractorsModel.contractorsData.totalPages()
        ) {
          angular.element('.pagination .next').hide().prev('.gap').hide()
        } else {
          angular.element('.pagination .next').show().prev('.gap').show()
        }
      }

      this.resetPagination = function (maxPageCount) {
        var totalPages = searchContractorsModel.contractorsData.totalPages()

        if (totalPages > 1) {
          $element.empty()
          if (!angular.element('.pagination').length) {
            $element.append('<nav class="pagination"></nav>')
          } // no pagination for previous filters

          if (!maxPageCount) {
            maxPageCount = totalPages > 5 ? 5 : totalPages
          }

          var paginationContainer = angular.element('.pagination')
          paginationContainer.empty()

          for (var currentPage = 1; currentPage <= totalPages; currentPage++) {
            if (currentPage === 1) {
              paginationContainer.append(
                "<span class='page current'>" + currentPage + '</span>'
              )
            } else {
              paginationContainer.append(
                '<span class="page"><a href="' +
                  searchContractorsModel.pageUrlWithFilters({
                    page: currentPage,
                  }) +
                  '">' +
                  currentPage +
                  '</a></span>'
              )
            }

            if (currentPage === maxPageCount && totalPages > 1) {
              if (currentPage < totalPages) {
                paginationContainer.append(
                  "<span class='page gap'>&hellip;</span>"
                )
              }

              paginationContainer.append(
                '<span class="next"><a rel="next" href="' +
                  searchContractorsModel.pageUrlWithFilters({ page: 2 }) +
                  '"><span>next page</span></a></span>'
              )
              break
            }
          }
        } else {
          $element.empty() // search result has no pagination
        }
      }

      this.currentPage = function (paginationItems) {
        return paginationItems.filter('.current').length > 0
          ? paginationItems
              .filter('.current')
              .html()
              .replace(/(\r\n|\n|\r)/gm, '')
              .replace(/\s/g, '')
          : null
      }

      this.paginationItems = function () {
        return angular.element('.pagination .page').not('.gap') // list of visible page links;
      }
    },
  ],
})
