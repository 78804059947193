/* global bz_app */
bz_app.component('searchResultsSummary', {
  bindings: {
    contractorsData: '<',
  },
  template:
    '<div>' +
    '<span ng-if="!$ctrl.isOnePageResult()">' +
    '<span class="hidden-sm hidden-xs">Showing</span> ' +
    '<span ng-bind="$ctrl.startRank() | number"></span> - <span ng-bind="$ctrl.stopRank() | number"></span> ' +
    'of <span class="hidden-sm hidden-xs">the best</span> ' +
    '<span ng-bind="$ctrl.contractorsData.total | number"></span> ' +
    '<span class="hidden-sm hidden-xs">experts</span>' +
    '</span>' +
    '<span ng-if="$ctrl.isOnePageResult()">' +
    'Showing all <span ng-bind="$ctrl.contractorsData.contractors.length"></span> experts' +
    '</span>' +
    '</div>',
  controller: [
    '$rootScope',
    '$timeout',
    '$element',
    function ($rootScope, $timeout, $element) {
      this.$onInit = function () {
        this.listenForResultsSet()
      }

      this.listenForResultsSet = function () {
        $rootScope.$on(
          'searchContractors.resultsSet',
          function () {
            $element.addClass('search-results-summary-updated')
            $timeout(function () {
              $element.removeClass('search-results-summary-updated')
            }, 2000)
          }.bind(this)
        )
      }

      this.startRank = function () {
        return (
          (this.contractorsData.page - 1) * this.contractorsData.pageSize + 1
        )
      }

      this.stopRank = function () {
        if (
          this.contractorsData.contractors.length <
          this.contractorsData.pageSize
        ) {
          return this.startRank() + this.contractorsData.contractors.length - 1
        } else {
          return this.startRank() + this.contractorsData.pageSize - 1
        }
      }

      this.isOnePageResult = function () {
        return this.contractorsData.total <= this.contractorsData.pageSize
          ? true
          : false
      }
    },
  ],
})
