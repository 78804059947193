bz_app.controller('CitiesPermitSumChartCtrl', [
  '$scope',
  'citiesPermitSumModel',
  'chartService',
  'chartObjectServiceV1',
  function ($scope, citiesPermitSumModel, chartService, chartObjectServiceV1) {
    $scope.Math = window.Math
    $scope.summaryData = citiesPermitSumModel.summaryData
    $scope.trendData = citiesPermitSumModel.trendData
    $scope.chartObject = angular.merge(
      chartObjectServiceV1,
      chartService.lineChartObject
    )
    $scope.chartObject.options.title = 'Building permits issued'
    $scope.chartObject.data = {
      cols: chartService.lineChartColumns,
      rows: $scope.trendData,
    }

    $scope.init = function (input) {
      $scope.location = input.location
      citiesPermitSumModel.processData(input.permitsSumData)
    }
  },
])
