/* global bz_app */
bz_app.component('contractorsResults', {
  bindings: {
    contractorsData: '<',
    filtersData: '<',
    inBetweenElement: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        'search_contractors/directives/contractors_results'
      )
    },
  ],
  controller: [
    function () {
      this.inBetweenPlacement = function ($index) {
        return (
          $index ===
          Math.min(Math.max(0, this.contractorsData.contractors.length - 3), 12)
        )
      }

      this.interviewAdPlacement = function ($index) {
        return $index === 10
      }

      this.subHeaderPlacement = function ($index) {
        return $index === 10 && this.isPrimaryUi(0)
      }

      this.subHeaderText = function () {
        return (
          this.filtersData.keywords +
          ' in ' +
          this.filtersData.locationName +
          ', ' +
          this.filtersData.locationState
        )
      }

      this.isPrimaryUi = function ($index) {
        return (
          $index < 10 &&
          this.contractorsData.top10ContractorsHavePhotos &&
          this.filtersData.isRelevanceSort() &&
          this.filtersData.locationSlug
        )
      }
    },
  ],
})
