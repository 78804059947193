/* global bz_app, ContentAPI */
bz_app.factory('stateServiceModel', [
  'attributeConversionService',
  function (attributeConversionService) {
    return {
      contentApi: new ContentAPI(),
      setStateYearServiceAboutText: function (input) {
        this.contentApi.setStateYearServiceAboutText(
          attributeConversionService.camelToUnderscoreAttributes(input)
        )
      },
    }
  },
])
