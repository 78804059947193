/* global bz_app, angular */
bz_app.component('nearByLocationsFilter', {
  controller: [
    '$rootScope',
    'searchContractorsModel',
    'trackSearchContractorsService',
    '$element',
    function (
      $rootScope,
      searchContractorsModel,
      trackSearchContractorsService,
      $element
    ) {
      this.$onInit = function () {
        this.listenForSearchResultsUpdate()
        this.trackNearByLocationLinkClick()
      }

      this.listenForSearchResultsUpdate = function () {
        $rootScope.$on(
          'searchContractors.contractorsData.set',
          function () {
            this.updateNearByLocationLinks()
          }.bind(this)
        )
      }

      this.updateNearByLocationLinks = function () {
        var nearByLocationItems = this.nearByLocationItems()

        if (nearByLocationItems && nearByLocationItems.length) {
          angular.forEach(nearByLocationItems, function (nearByLocationItem) {
            var itemElement = angular.element(nearByLocationItem)
            itemElement.find('a').attr(
              'href',
              searchContractorsModel.pageUrlWithFilters({
                locationSlug: itemElement.data('location-slug'),
              })
            )
          })
        }
      }

      this.trackNearByLocationLinkClick = function () {
        $element.find('.filter-set-item-link a').click(function ($event) {
          var itemElement = angular.element(this)

          var redirectToLocation = function () {
            document.location = itemElement.attr('href')
          }

          trackSearchContractorsService.filterClicked(
            'location',
            itemElement.parent().data('location-slug'),
            null,
            redirectToLocation
          )
          $event.preventDefault()
        })
      }

      this.nearByLocationItems = function () {
        return $element
          .find('.filter-set-item-link')
          .not('.filter-set-item-link-selected')
      }
    },
  ],
})
