/* globals bz_app, angular */
bz_app.component('searchResultsSummaryContainer', {
  controller: [
    '$compile',
    'searchContractorsModel',
    '$scope',
    function ($compile, searchContractorsModel, $scope) {
      this.$onInit = function () {
        this.contractorsData = searchContractorsModel.contractorsData
        this.contractorResultsUpdated = false

        $scope.$watchCollection(
          '$ctrl.contractorsData',
          function (newValue, oldValue) {
            if (newValue === oldValue) {
              return
            }

            if (!this.contractorResultsUpdated) {
              this.contractorResultsUpdated = true
              var container = angular.element('.search-results-summary')
              container.empty()
              container.append(
                $compile(
                  '<search-results-summary ng-if="$ctrl.contractorsData.total" contractors-data="$ctrl.contractorsData"></search-results-summary>'
                )($scope)
              )
            }
          }.bind(this)
        )
      }
    },
  ],
})
