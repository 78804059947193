bz_app.factory('citiesPermitIndexModel', [
  '$filter',
  'buildingPermitModel',
  function ($filter, buildingPermitModel) {
    return {
      trendData: [],
      summaryData: {},
      loadData: function (location) {
        var that = this

        return buildingPermitModel
          .load_permits_index_by_month_in_city(location.slug)
          .then(function (response) {
            that.processData(response)
          })
      },
      processData: function (response) {
        if (response.success) {
          this.setSummary(response.summary)
          this.setTrend(response.trend)
        }
      },
      setSummary: function (summary) {
        if (summary) this.summaryData = summary
      },
      setTrend: function (trend) {
        if (trend) {
          var that = this
          var residential_new_construction_index =
            'Residential New Construction'
          var residential_remodeling_and_maintenance_index =
            'Residential Remodeling and Maintenance'

          angular.forEach(trend, function (item) {
            var date = $filter('date')(
              new Date(item.year, item.month - 1),
              'MMM `yy'
            )
            that.trendData.push({
              c: [
                { v: date },
                { v: item[residential_new_construction_index] },
                {
                  v:
                    residential_new_construction_index +
                    ': ' +
                    $filter('number')(
                      item[residential_new_construction_index]
                    ) +
                    ' (' +
                    date +
                    ')',
                },
                { v: item[residential_remodeling_and_maintenance_index] },
                {
                  v:
                    residential_remodeling_and_maintenance_index +
                    ': ' +
                    $filter('number')(
                      item[residential_remodeling_and_maintenance_index]
                    ) +
                    ' (' +
                    date +
                    ')',
                },
              ],
            })
          })
        }
      },
    }
  },
])
