/* global bz_app */
bz_app.directive('redirectOnClick', [
  function () {
    return {
      restrict: 'A',
      scope: {
        redirectUrl: '@',
      },
      link: function (scope, element) {
        element.on('click', function () {
          document.location = scope.redirectUrl
        })
      },
    }
  },
])
