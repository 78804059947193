/* global bz_app, angular */
bz_app.component('recentProjectsContainer', {
  controller: [
    '$compile',
    'searchContractorsModel',
    '$scope',
    function ($compile, searchContractorsModel, $scope) {
      this.$onInit = function () {
        this.recentProjects = searchContractorsModel.recentProjectsData
        this.recentProjectsUpdated = false

        $scope.$watch(
          '$ctrl.recentProjects',
          function (newValue, oldValue) {
            if (newValue === oldValue) {
              return
            }

            // initial server-side data has not been replaced yet
            if (!this.recentProjectsUpdated) {
              this.recentProjectsUpdated = true

              var propertiesContainer = angular.element(
                '.search-contractors-recent-projects-list'
              )

              if (propertiesContainer.length) {
                propertiesContainer.empty()
                propertiesContainer.prepend(
                  $compile(
                    '<recent-projects properties="$ctrl.recentProjects.properties"></recent-projects>'
                  )($scope)
                )
              }
            }
          }.bind(this),
          true
        )
      }
    },
  ],
})
