/* global bz_app, angular, $ */
bz_app.factory('searchContractorsModel', [
  'recentProjectsData',
  'contractorsData',
  'filtersData',
  'attributeConversionService',
  function (
    recentProjectsData,
    contractorsData,
    filtersData,
    attributeConversionService
  ) {
    return {
      searchContractorsAPI: new SearchContractorsAPI(),
      contractorsData: contractorsData,
      recentProjectsData: recentProjectsData,
      filtersData: filtersData,

      load: function () {
        return this.searchContractorsAPI.load(
          this.filtersData.locationSlug,
          this.filtersData.keywordsSlug,
          this.allowedFilters()
        )
      },
      loadSponsored: function (excludedContractorIds) {
        return this.searchContractorsAPI.load_sponsored(
          this.filtersData.locationSlug,
          this.filtersData.keywordsSlug,
          this.allowedFilters(),
          excludedContractorIds
        )
      },
      pageUrlWithFilters: function (filters) {
        var locationSlug =
          filters && filters.locationSlug
            ? filters.locationSlug
            : this.filtersData.locationSlug

        if (this.filtersData.keywordsSlug) {
          var keywordsSlug =
            filters && filters.keywordsSlug
              ? filters.keywordsSlug
              : this.filtersData.keywordsSlug
          return (
            '/' +
            locationSlug +
            '/' +
            keywordsSlug +
            '?' +
            this.filtersQueryString(filters)
          )
        } else {
          return '/' + locationSlug + '?' + this.filtersQueryString(filters)
        }
      },
      filtersQueryString: function (tmpFiltersUpdates) {
        return $.param(this.allowedFilters(tmpFiltersUpdates))
      },
      allowedFilters: function (tmpFiltersUpdates) {
        var queryItems = angular.copy(this.filtersData)

        if (tmpFiltersUpdates) {
          angular.forEach(tmpFiltersUpdates, function (value, index) {
            queryItems[index] = value
          })
        }

        // remove non-filter attributes
        delete queryItems.locationSlug
        delete queryItems.keywordsSlug
        delete queryItems.locationName
        delete queryItems.isFilteredSearch
        delete queryItems.set
        delete queryItems.reset
        delete queryItems.setProjectValue
        delete queryItems.setContractorWorkPreferences
        delete queryItems.currentSortLabel
        delete queryItems.setRadius
        delete queryItems.setWealthTier
        delete queryItems.setWealthTierOption
        delete queryItems.init

        this.calculateProjectValueRange(queryItems)
        this.setRadius(queryItems)
        this.setWealthTier(queryItems)

        // convert camel-case to underscore attributes and remove all attributes with no values
        angular.forEach(queryItems, function (value, index) {
          if (value) {
            if (
              index === 'contractorType' &&
              $.inArray(value, ['residential', 'commercial']) === -1
            )
              delete queryItems[index]
            else {
              var newIndex = attributeConversionService.camelToUnderscore(index)
              queryItems[newIndex] = value

              if (newIndex !== index) {
                delete queryItems[index]
              }
            }
          } else {
            delete queryItems[index]
          }
        })

        return queryItems
      },
      calculateProjectValueRange: function (queryItems) {
        if (queryItems.projectValue) {
          var min = null
          var max = null

          if (queryItems.projectValue.max10k) {
            max = 10000
          } else if (queryItems.projectValue.max50k) {
            max = 50000
          } else if (queryItems.projectValue.max100k) {
            max = 100000
          } else if (queryItems.projectValue.min100k) {
            min = 100000
          }

          if (min) {
            queryItems.minJobValue = min
          }

          if (max) {
            queryItems.maxJobValue = max
          }
        }

        delete queryItems.projectValue
      },
      setRadius: function (queryItems) {
        if (queryItems.mileRadiusOptions) {
          if (queryItems.mileRadiusOptions.maxCity) {
            queryItems.radius = 'city'
            delete queryItems.mileRadius
          } else {
            queryItems.radius = 'metro'
            queryItems.mileRadius = 50

            if (queryItems.mileRadiusOptions.max10) {
              queryItems.mileRadius = 10
            } else if (queryItems.mileRadiusOptions.max25) {
              queryItems.mileRadius = 25
            }
          }
        }

        delete queryItems.mileRadiusOptions
      },
      setWealthTier: function (queryItems) {
        if (queryItems.wealthTier && !queryItems.wealthTier.all) {
          if (queryItems.wealthTier.mid) {
            queryItems.minWealthTier = queryItems.wealthTierOptions.mid.min
            queryItems.maxWealthTier = queryItems.wealthTierOptions.mid.max
          }

          if (queryItems.wealthTier.high) {
            queryItems.maxWealthTier = queryItems.wealthTierOptions.high.max

            if (!queryItems.wealthTier.mid) {
              queryItems.minWealthTier = queryItems.wealthTierOptions.high.min
            }
          }
        }

        delete queryItems.wealthTier
        delete queryItems.wealthTierOptions
      },
    }
  },
])
