bz_app.factory('chartService', function () {
  return {
    lineChartObject: {
      type: 'LineChart',
      options: {
        title: '-',
      },
    },
    lineChartColumns: [
      { label: 'Year', type: 'string' },
      { label: 'Building permits', type: 'number' },
      { type: 'string', role: 'tooltip' },
    ],
    lineIndexChartColumns: [
      { label: 'Year', type: 'string' },
      { label: 'New construction - Residential', type: 'number' },
      { type: 'string', role: 'tooltip' },
      { label: 'Remodeling - Residential', type: 'number' },
      { type: 'string', role: 'tooltip' },
    ],
  }
})
