bz_app.component('cityContractorsFilters', {
  bindings: {
    contractorTypes: '<',
    activeContractorType: '=',
    years: '<',
    activeYear: '=',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl(
        'cities/components/city_contractors_filters'
      )
    },
  ],
  controller: [
    function () {
      this.selectContractorType = function (contractorType) {
        this.activeContractorType = contractorType
      }

      this.contractorTypeIsSelected = function (contractorType) {
        return this.activeContractorType.id === contractorType.id
      }

      this.selectYear = function (year) {
        this.activeYear = year
      }

      this.yearIsSelected = function (year) {
        return this.activeYear === year
      }
    },
  ],
})
