/* globals openClaimModal */

// TD: I am pretty sure this file is no longer used and can be removed
// if it is used it doesn't work

var CitiesShow = function () {
  this.init = function () {
    //lazy loading of images (http://www.appelsiini.net/projects/lazyload)
    $('img.lazy-load').lazyload({ threshold: 500 })
  }

  this.loadSignUpOnlyModal = function () {
    setTimeout(function () {
      openClaimModal()
    }, 4000)

    $('#greeting .btn').click(function () {
      openClaimModal()
    })

    new SignInUp().showModalCallback()
    mixpanel.track('Registration', { action: 'load sign-up only modal' })

    $('#signUpOnlyModal form .btn').click(function () {
      mixpanel.track('Registration', {
        action: 'click to submit sign-up only modal',
      })
    })
  }
}

$(function () {
  var cities_show = new CitiesShow()
  cities_show.init()
})

window.CitiesShow = CitiesShow
