var bz_app = angular.module(
  'bz_app',
  [
    'ui.bootstrap',
    'googlechart',
    'xeditable',
    'bzYelpModule',
    'bzMapModule',
  ].concat(defaultAngularModuleSet)
)

bz_app.run([
  'editableOptions',
  function (editableOptions) {
    editableOptions.theme = 'bs3' // bootstrap3 theme. Can be also 'bs2', 'default'
  },
])

angular.element(document).ready(function () {
  angular.bootstrap(document, ['bz_app'])
})

window.bz_app = bz_app
