bz_app.factory('chartObjectServiceV1', function () {
  return {
    options: {
      titleTextStyle: {
        fontSize: '14',
        bold: false,
      },
      legend: {
        position: 'none',
        textStyle: {
          fontSize: '12',
        },
      },
      vAxis: {
        textStyle: {
          //bold: true,
          italic: true,
        },
        minValue: 0,
      },
      hAxis: {
        textStyle: {
          //bold: true,
          italic: true,
          color: '#979797',
        },
      },
      backgroundColor: '#f8f8f8',
      fontName: 'Open Sans',
      tooltip: {
        textStyle: {
          italic: true,
          fontSize: '13',
        },
      },
      lineWidth: 4,
      colors: ['#4c92e0', '#63b75f'],
    },
  }
})
