/* global bz_app, angular */
bz_app.component('servicesFilter', {
  controller: [
    '$rootScope',
    '$element',
    'searchContractorsModel',
    'trackSearchContractorsService',
    function (
      $rootScope,
      $element,
      searchContractorsModel,
      trackSearchContractorsService
    ) {
      this.$onInit = function () {
        this.listenForSearchResultsUpdate()
        this.trackServiceLinkClick()
      }

      this.listenForSearchResultsUpdate = function () {
        $rootScope.$on(
          'searchContractors.contractorsData.set',
          function () {
            this.updateServiceLinks()
          }.bind(this)
        )
      }

      this.updateServiceLinks = function () {
        var serviceLinkItems = this.serviceLinkItems()

        if (serviceLinkItems && serviceLinkItems.length) {
          angular.forEach(serviceLinkItems, function (serviceLinkItem) {
            var itemElement = angular.element(serviceLinkItem)
            itemElement.find('a').attr(
              'href',
              searchContractorsModel.pageUrlWithFilters({
                keywordsSlug: itemElement.data('service-slug'),
              })
            )
          })
        }
      }

      this.trackServiceLinkClick = function () {
        $element.find('.filter-set-item-link a').click(function ($event) {
          var itemElement = angular.element(this)

          var redirectToService = function () {
            document.location = itemElement.attr('href')
          }

          trackSearchContractorsService.filterClicked(
            'service',
            itemElement.parent().data('service-slug'),
            null,
            redirectToService
          )
          $event.preventDefault()
        })
      }

      this.serviceLinkItems = function () {
        return $element
          .find('.filter-set-item-link')
          .not('.filter-set-item-link-selected')
      }
    },
  ],
})
