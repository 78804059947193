bz_app.directive('collapsible', function () {
  return {
    restrict: 'A',
    scope: {
      collapsed: '=',
    },
    transclude: true,
    link: function ($scope, element, attrs, ctrl, transclude) {
      transclude($scope, function (clone) {
        element.append(clone)
      })
    },
  }
})
