bz_app.controller('CitiesPermitIndexChartCtrl', [
  '$scope',
  '$timeout',
  'citiesPermitIndexModel',
  'chartService',
  'chartObjectServiceV1',
  function (
    $scope,
    $timeout,
    citiesPermitIndexModel,
    chartService,
    chartObjectServiceV1
  ) {
    $scope.init = function (input) {
      $scope.location = input.location
      $scope.Math = window.Math
      $scope.summaryData = citiesPermitIndexModel.summaryData
      $scope.trendData = citiesPermitIndexModel.trendData

      $scope.initChartObject(input)

      citiesPermitIndexModel.processData(input.permitsIndexData) // chart data loaded from server-side controller input

      $timeout(function () {
        $scope.$apply(function () {
          $scope.summaryData = citiesPermitIndexModel.summaryData
          $scope.trendData = citiesPermitIndexModel.trendData
        })
      }, 0)
    }

    $scope.initChartObject = function (input) {
      $scope.chartObject = angular.merge(
        chartObjectServiceV1,
        chartService.lineChartObject
      )
      $scope.chartObject.options.title = 'Building permit application activity'
      $scope.chartObject.options.vAxis.title = input.vAxisLabel
        ? input.vAxisLabel
        : ''
      $scope.chartObject.options.legend.position =
        input.chartLegend && input.chartLegend.position
          ? input.chartLegend.position
          : 'none'

      // display legend only for index with data
      $scope.chartObject.options.series = [
        {
          visibleInLegend: input.chartLegend.residential_new_construction
            ? true
            : false,
        },
        {
          visibleInLegend: input.chartLegend
            .residential_remodeling_and_maintenance
            ? true
            : false,
        },
      ]

      $scope.chartObject.data = {
        cols: chartService.lineIndexChartColumns,
        rows: $scope.trendData,
      }
    }
  },
])
