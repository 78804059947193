/* global bz_app, isMobile */
bz_app.factory('filtersService', [
  function () {
    return {
      isOpen: true,
      toggleVisibility: function () {
        this.isOpen = !this.isOpen
      },
      closeOnMobile: function () {
        if (isMobile.check({ phoneOnly: true }) && this.isOpen) {
          this.toggleVisibility()
        }
      },
    }
  },
])
