/**	class to access contractor api	*/
var ContentAPI = function () {
  this.setStateYearServiceAboutText = function (input) {
    return $.when(
      $.ajax({
        url: this.state_api_path() + '/content',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(input),
        dataType: 'json',
      })
    )
  }
}

ContentAPI.prototype = new BZAPI()
ContentAPI.prototype.constructor = ContentAPI

window.ContentAPI = ContentAPI
