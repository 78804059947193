/* global bz_app, angular */
bz_app.component('contractorsResultsContainer', {
  bindings: {},
  controller: [
    '$compile',
    '$scope',
    'searchContractorsModel',
    function ($compile, $scope, searchContractorsModel) {
      this.$onInit = function () {
        this.contractorsData = searchContractorsModel.contractorsData
        this.contractorResultsUpdated = false
        this.filtersData = searchContractorsModel.filtersData

        $scope.$watchCollection(
          '$ctrl.contractorsData',
          (newValue, oldValue) => {
            if (newValue === oldValue) {
              return
            }

            this.inBetween = angular
              .element('.inbetween-hidden .inbetween')
              .clone()

            if (!this.contractorResultsUpdated) {
              this.contractorResultsUpdated = true
              let contractorsContainer = angular.element(
                '.search-result-contractors'
              )
              contractorsContainer.empty()
              contractorsContainer.prepend(
                $compile(
                  '<contractors-results contractors-data="$ctrl.contractorsData" filters-data="$ctrl.filtersData" in-between-element="$ctrl.inBetween"></contractors-results>' +
                    '<no-results class="no-results" ng-if="!$ctrl.contractorsData.total" filters-data="$ctrl.filtersData"></no-results>'
                )($scope)
              )
            }
          }
        )
      }
    },
  ],
})
