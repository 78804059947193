bz_app.component('noResults', {
  bindings: {
    filtersData: '<',
  },
  templateUrl: [
    'urlService',
    function (urlService) {
      return urlService.templateUrl('search_contractors/directives/no_results')
    },
  ],
  controller: [
    function () {
      this.withFilters = function () {
        var filters = []
        if (this.filtersData.hasReviews) {
          filters.push('reviews')
        }
        if (this.filtersData.hasPermits) {
          filters.push('verified projects')
        }
        if (this.filtersData.hasPhotos) {
          filters.push('photos')
        }
        if (filters.length > 1) {
          filters.push('and ' + filters.pop())
        }
        if (filters.length > 0) {
          if (filters.length < 3) {
            return ' with ' + filters.join(' ')
          } else {
            return ' with ' + filters.join(', ')
          }
        }
      }

      this.workPreferences = function () {
        if (
          this.filtersData.workPreferences.residential &&
          !this.filtersData.workPreferences.commercial
        ) {
          return ' residential'
        } else if (
          !this.filtersData.workPreferences.residential &&
          this.filtersData.workPreferences.commercial
        ) {
          return ' commercial'
        }
      }

      this.keywordsSlugClean = function () {
        return this.filtersData.keywordsSlug.replace(/-/g, ' ')
      }
    },
  ],
})
