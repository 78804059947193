/* global bz_app, Track */
bz_app.directive('phoneCallLink', [
  function () {
    return {
      restrict: 'AE',
      scope: {
        phone: '@',
        analyticsAction: '@',
      },
      link: function (scope, element) {
        element.click(function (e) {
          Track.send('Call Customer Support', { action: scope.analyticsAction })
          document.location = 'tel:' + scope.phone
          e.preventDefault()
        })
      },
    }
  },
])
