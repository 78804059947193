bz_app.factory('cityContractorsModel', [
  '$http',
  'urlConstants',
  'attributeConversionService',
  function ($http, urlConstants, attributeConversionService) {
    return {
      apiPath: urlConstants.api.v1.path + 'cities/',
      searchContractors: function (data) {
        var query =
          attributeConversionService.camelToUnderscoreDeepAttributes(data)
        return $http
          .get(this.apiPath + 'search_contractors', { params: query })
          .then(this.extractData)
      },
      topContractorsServices: function (data) {
        var query =
          attributeConversionService.camelToUnderscoreDeepAttributes(data)
        return $http
          .get(this.apiPath + 'top_contractor_types', { params: query })
          .then(this.extractData)
      },
      extractData: function (response) {
        if (response.data) {
          return attributeConversionService.underscoreToCamelDeepAttributes(
            response.data
          )
        } else {
          return null
        }
      },
    }
  },
])
