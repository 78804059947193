bz_app.factory('recentProjectsData', [
  '$rootScope',
  '$timeout',
  function ($rootScope, $timeout) {
    return {
      properties: null,
      init: false,

      set: function (input) {
        var that = this
        $timeout(function () {
          $rootScope.$apply(function () {
            that.properties = input !== undefined ? input : null
            that.init = true
          })
        })
      },
    }
  },
])
