/* global bz_app */
bz_app.factory('filtersData', [
  '$rootScope',
  '$timeout',
  'filtersService',
  function ($rootScope, $timeout, filtersService) {
    return {
      sort: '',
      locationSlug: '',
      keywordsSlug: '',
      page: 1,
      pageSize: 15,
      locationId: null,
      locationName: '',
      radius: '',
      mileRadius: null,
      mileRadiusOptions: {
        maxCity: false,
        max10: false,
        max25: false,
        max50: false,
      },
      workPreferences: {
        residential: false,
        commercial: false,
      },
      isResidentialType: false,
      isCommercialType: false,
      hasPhotos: false,
      hasReviews: false,
      hasPermits: false,
      searchType: '',
      projectValue: {
        max10k: false,
        max50k: false,
        max100k: false,
        min100k: false,
        all: false,
      },
      wealthTier: {
        high: false,
        mid: false,
        all: false,
      },
      wealthTierOptions: {
        high: { min: null, max: null },
        mid: { min: null, max: null },
      },
      init: false,

      set: function (input, callback) {
        $timeout(
          function () {
            $rootScope.$apply(
              function () {
                if (input) {
                  if (input.sort !== undefined) {
                    this.sort = input.sort
                  }
                  if (input.locationSlug !== undefined) {
                    this.locationSlug = input.locationSlug
                  }
                  if (input.keywordsSlug !== undefined) {
                    this.keywordsSlug = input.keywordsSlug
                  }
                  if (input.keywords !== undefined) {
                    this.keywords = input.keywords
                  }
                  if (input.page !== undefined) {
                    this.page = input.page
                  }
                  if (input.pageSize !== undefined) {
                    this.pageSize = input.pageSize
                  }
                  if (input.locationId !== undefined) {
                    this.locationId = input.locationId
                  }
                  if (input.locationName !== undefined) {
                    this.locationName = input.locationName
                  }
                  if (input.locationState !== undefined) {
                    this.locationState = input.locationState
                  }
                  if (input.hasPhotos !== undefined) {
                    this.hasPhotos = input.hasPhotos
                  }
                  if (input.hasReviews !== undefined) {
                    this.hasReviews = input.hasReviews
                  }
                  if (input.hasPermits !== undefined) {
                    this.hasPermits = input.hasPermits
                  }
                  if (input.searchType !== undefined) {
                    this.searchType = input.searchType
                  }
                  if (input.workPreferences !== undefined) {
                    this.workPreferences = input.workPreferences
                  }

                  this.setContractorWorkPreferences(input)
                  this.setProjectValue(input)
                  this.setProjectValue(input)
                  this.setRadius(input)
                  this.setWealthTier(input)

                  if (input.init !== undefined) {
                    this.init = input.init
                  } else {
                    this.init = true
                  }
                }
              }.bind(this)
            )
            if (callback) {
              callback()
            }
          }.bind(this)
        )
      },
      setContractorWorkPreferences: function (input) {
        if (input && input.workPreferences !== undefined) {
          this.isResidentialType = input.workPreferences.residential
          this.isCommercialType = input.workPreferences.commercial
        }
      },
      setProjectValue: function (input) {
        if (input) {
          if (input.minJobValue === 100000) {
            this.projectValue.min100k = true
          } else if (input.maxJobValue) {
            switch (input.maxJobValue) {
              case 10000:
                this.projectValue.max10k = true
                break
              case 50000:
                this.projectValue.max50k = true
                break
              case 100000:
                this.projectValue.max100k = true
                break
            }
          } else {
            this.projectValue.all = true
          }
        }
      },
      setRadius: function (input) {
        if (input) {
          if (input.radius !== undefined) {
            this.radius = input.radius
          }
          if (input.mileRadius !== undefined) {
            this.mileRadius = input.mileRadius
          }

          if (input.radius) {
            this.mileRadiusOptions.maxCity = false
            this.mileRadiusOptions.max10 = false
            this.mileRadiusOptions.max25 = false
            this.mileRadiusOptions.max50 = false

            if (this.radius === 'city') {
              this.mileRadiusOptions.maxCity = true
            } else if (this.radius === 'metro') {
              if (this.mileRadius) {
                if (this.mileRadius >= 10 && this.mileRadius < 25) {
                  this.mileRadiusOptions.max10 = true
                } else if (this.mileRadius >= 25 && this.mileRadius < 50) {
                  this.mileRadiusOptions.max25 = true
                } else if (this.mileRadius >= 50) {
                  this.mileRadiusOptions.max50 = true
                } else {
                  this.mileRadiusOptions.max10 = true
                }
              } else {
                this.mileRadiusOptions.max10 = true
              }
            }
          }
        }
      },
      setWealthTier: function (input) {
        if (input) {
          if (input.wealthTierOptions !== undefined) {
            this.setWealthTierOption(input.wealthTierOptions, 'high')
            this.setWealthTierOption(input.wealthTierOptions, 'mid')
          }

          if (input.wealthTier !== undefined) {
            if (
              input.wealthTier.min !== undefined &&
              input.wealthTier.min !== null &&
              input.wealthTier.max !== undefined &&
              input.wealthTier.max !== null
            ) {
              if (input.wealthTier.min < this.wealthTierOptions.mid.min) {
                this.wealthTier.all = true
              }

              if (
                !this.wealthTier.all &&
                input.wealthTier.max >= this.wealthTierOptions.high.min
              ) {
                this.wealthTier.high = true
              }

              if (
                !this.wealthTier.all &&
                input.wealthTier.max >= this.wealthTierOptions.mid.min
              ) {
                this.wealthTier.mid = true
              }
            } else {
              this.wealthTier.all = true
            }
          }
        }
      },
      setWealthTierOption: function (wealthTierOptions, optionKey) {
        if (
          wealthTierOptions &&
          wealthTierOptions[optionKey] &&
          wealthTierOptions[optionKey].length === 2
        ) {
          this.wealthTierOptions[optionKey].min =
            wealthTierOptions[optionKey][0]
          this.wealthTierOptions[optionKey].max =
            wealthTierOptions[optionKey][1]
        }
      },
      reset: function () {
        $timeout(
          function () {
            $rootScope.$apply(
              function () {
                this.set({
                  sort: 'buildzoom_score',
                  page: 1,
                  radius: 'metro',
                  isResidentialType: true,
                  isCommercialType: true,
                  hasPhotos: false,
                  hasReviews: false,
                  hasPermits: false,
                })
              }.bind(this)
            )
          }.bind(this)
        )
      },
      isFilteredSearch: function () {
        return this.searchType === 'filtered' ? true : false
      },
      isRelevanceSort: function () {
        return this.sort === 'relevance'
      },
      currentSortLabel: function () {
        var label = ''
        switch (this.sort) {
          case 'relevance':
            label = 'Top 10 contractors'
            break
          case 'buildzoom_score':
            label = 'BuildZoom score'
            break
          case 'num_permits':
            label = 'Verified projects'
            break
          case 'avg_review_score':
            label = 'Client ratings'
            break
          case 'median_job_value':
            label = 'Avg project value'
            break
        }

        return label
      },
    }
  },
])
