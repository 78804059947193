/* global bz_app */
bz_app.component('inBetween', {
  bindings: {
    inBetweenElement: '<',
  },
  controller: [
    '$compile',
    '$element',
    '$scope',
    function ($compile, $element, $scope) {
      this.$onInit = () => {
        $element.append($compile(this.inBetweenElement)($scope))
      }
    },
  ],
})
